import React from "react";
import * as ReactDOMClient from "react-dom/client";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { ProtectionPage } from "./ProtectionPage";
import { CheckoutForm } from "./CheckoutForm";
import PaymentsComponent from "./PaymentsComponent";

export default function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<ProtectionPage />} />
          <Route path="/checkout" element={<CheckoutForm />} />
          <Route path="/payments-form" element={<PaymentsComponent />} />
        </Routes>
      </Router>
  );
}

ReactDOMClient.createRoot(document.getElementById("root")).render(<App />);
