import "./App.css";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AmountField } from "./custom/AmountField";
import { NameField } from "./custom/NameField";
import { EmailField } from "./custom/EmailField";
import { OrderIdField } from "./custom/OrderIdField";

export function CheckoutForm() {
  const [amount, setAmount] = useState("");
  const [orderId, setOrderId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const getAmount = useCallback(
    (e) => {
      const value = e.target.value;

      const cleanedValue = value.replace(/,/g, ".");
      const parts = cleanedValue.split(".");

      if (parts.length > 1) {
        const integerPart = parts[0].replace(/\./g, "");
        const decimalPart = parts[1];

        const finalValue = `${parseInt(integerPart, 10)}.${decimalPart}`;

        setAmount(finalValue);
      } else {
        const finalValue = cleanedValue.replace(/\./g, "");
        setAmount(parseInt(finalValue, 10).toString());
      }
    },
    [setAmount]
  );

  const getOrderId = useCallback(
    (e) => {
      setOrderId(e.target.value);
    },
    [setOrderId]
  );

  const getName = useCallback(
    (e) => {
      setName(e.target.value);
    },
    []
  );

  const getEmail = useCallback(
    (e) => {
      setEmail(e.target.value);
    },
    [setEmail]
  );

  const onSubmitCheckout = useCallback(
    async (e) => {
      e.preventDefault();

      navigate("/payments-form", { state: { amount, orderId, name, email } });
    },
    [amount, email, name, navigate, orderId]
  );

  const isAmountValid = /^[0-9]*(\.[0-9]*)?$/.test(amount);

  return (
    <>
      <div className="checkout-container">
        <img
          alt="logo"
          title="Lamborghini logo"
          className="logo-img"
          src="./images/logo.png"
        />
        <form onSubmit={onSubmitCheckout}>
          <h2 className="form-title">Personal details and orderId</h2>
          <NameField getName={getName} name={name} />
          <div className="separator"></div>
          <EmailField getEmail={getEmail} email={email} />
          <div className="separator"></div>
          <OrderIdField getOrderId={getOrderId} orderId={orderId} />
          <div className="separator"></div>
          <AmountField onAmountChange={getAmount} amount={amount} />
          <input
            type="submit"
            value="Submit"
            className="btn btn-success checkout-btn"
            disabled={!name || !email || !amount || !orderId || amount < 0.5 || !isAmountValid}
          />
        </form>
      </div>
    </>
  );
}