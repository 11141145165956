import React from 'react';
import './spinner.css'; 

export default function Spinner() {
  return (
    <div className="spinner">
      <div className="loader"></div>
    </div>
  );
};
