import React from "react";
import { useLocation } from "react-router-dom";

export function AmountField({
  onAmountChange,
  amount
}) {
  const location = useLocation();
  const isReadOnlyField = location.pathname === "/payments-form";

  return (
    <>
      <div className="custom-field-container">
        <label>
          Amount ($)
          {location.pathname === "/checkout" && (
            <span className="amount-message">
              *Please note that the minimum required must be $0.50 (50 cents)
            </span>
          )}
        </label>
        <div>
          <input
            type="text"
            placeholder="Enter your amount here"
            id="customField"
            className="p-LinkInput"
            name="quantity"
            defaultValue={amount}
            onChange={onAmountChange}
            readOnly={isReadOnlyField}
          />
        </div>
      </div>
    </>
  );
}
