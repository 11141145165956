import "./App.css";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Spinner from "./spinner/Spinner";
import { getAccessToken, createPaymentIntent } from "./tokenUtils";
import ConfirmTokenForm from "./confirmTokenForm";
import { tokenUrl, paymentUrl, clientCredentials } from "./constants/constants";

const stripePromise = loadStripe(
  "pk_test_51P6G8oFM3VxlJSED9MEzI21NX1aMPGMHu4woWJLoy7A5kBCJQhUrSbxffX7SJ2wDr5KEO9dTPAkN1cyUDJUM1anT00Dpp5rwOR"
);

export default function PaymentsComponent() {
  const [stripeOptions, setStripeOptions] = useState();
  const [correlationId, setCorrelationId] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { name, email, amount, orderId } = location.state;

  const getAccessTokenAndSetCorrelationId = useCallback(async () => {
    const data = await getAccessToken(tokenUrl, clientCredentials);
    const newCorrelationId = `Lambo-test-${uuidv4()}`;
    setCorrelationId(newCorrelationId);
    setAccessToken(data.access_token);
    return { accessToken: data.access_token, correlationId: newCorrelationId };
  }, []);

  const createIntentPayment = useCallback(
    async (accessToken, correlationId) => {
      const paymentIntent = await createPaymentIntent(
        paymentUrl,
        accessToken,
        correlationId,
        amount,
        orderId,
        name,
        email,
        "https://squadracorse-na.com/" 
      );

      console.log(paymentIntent);

      const options = {
        clientSecret: paymentIntent.data.PaymentIntentCreate.ClientSecret,
        paymentId: paymentIntent.data.PaymentIntentCreate.PaymentIntentId,
      };

      setStripeOptions(options);
      setPaymentIntentId(options.paymentId);
    },
    [amount, email, name, orderId]
  );

  const getToken = useCallback(async () => {
    const { accessToken, correlationId } =
      await getAccessTokenAndSetCorrelationId();
    await createIntentPayment(accessToken, correlationId);
    console.log(accessToken);
  }, [createIntentPayment, getAccessTokenAndSetCorrelationId]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
    getToken();
  }, [getToken]);

  return (
    <main className="main-container">
      {loading ? (
        <Spinner />
      ) : stripeOptions ? (
        <>
          <img
            alt="logo"
            title="Lamborghini logo"
            className="logo-img"
            src="./images/logo.png"
          />
          <Elements stripe={stripePromise} options={stripeOptions}>
            <ConfirmTokenForm
              name={name}
              email={email}
              amount={amount}
              orderId={orderId}
              accessToken={accessToken}
              paymentIntentId={paymentIntentId}
              correlationId={correlationId}
            />
          </Elements>
        </>
      ) : (
        <></>
      )}
    </main>
  );
}