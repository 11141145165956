import "./App.css";
import React, { useCallback, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { confirmPaymentIntent } from "./tokenUtils";
import { paymentUrl } from "./constants/constants";
import { AddressForm } from "./AddressForm";
import { AmountField } from "./custom/AmountField";
import { OrderIdField } from "./custom/OrderIdField";
import { NameField } from "./custom/NameField";
import { EmailField } from "./custom/EmailField";

export default function ConfirmTokenForm({
  onAmountChange,
  getOrderId,
  getName,
  getEmail,
  amount,
  name,
  email,
  orderId,
  accessToken,
  paymentIntentId,
  correlationId,
}) {
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleError = useCallback((error) => {
    setLoading(false);
    setErrorMessage(error.message);
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      const { error: submitError } = await elements.submit();

      if (submitError) {
        handleError(submitError);
        return;
      }

      const result = await stripe.createConfirmationToken({
        elements,
        params: {
          return_url: `https://lamborghinipayments.squadracorse-na.com/`,
        },
      });

      console.log(result.confirmationToken);

      console.log("Confirmation token", result.confirmationToken.id);

      if (result.error) {
        console.error(result.error.code);
      } else {
        console.log("result", result);

        console.log(
          paymentUrl,
          accessToken,
          paymentIntentId,
          result.confirmationToken.id,
          correlationId,
          orderId,
          name,
          email
        );

        await confirmPaymentIntent(
          paymentUrl,
          accessToken,
          paymentIntentId,
          result.confirmationToken.id,
          correlationId,
          orderId,
          name,
          email
        );
      }
    },
    [accessToken, correlationId, elements, email, handleError, name, orderId, paymentIntentId, stripe]
  );

  return (
    <div>
      <form onSubmit={handleSubmit} className="main-form">
        <h2 className="form-title">Billing information</h2>
        <div className="separator"></div>
        <NameField getName={getName} name={name} />
        <div className="separator"></div>
        <AddressForm />
        <div className="separator"></div>
        <EmailField getEmail={getEmail} email={email} />
        <div className="separator"></div>
        <h2 className="form-title">Payment information</h2>
        <div className="separator"></div>
        <OrderIdField getOrderId={getOrderId} orderId={orderId} />
        <div className="separator"></div>
        <PaymentElement />
        <div className="separator"></div>
        <AmountField onAmountChange={onAmountChange} amount={amount} />
        <div className="separator"></div>
        <button
          type="submit"
          id="submitButton"
          className="btn btn-success"
          disabled={!stripe || loading}
        >
          Submit
        </button>
        {errorMessage && (
          <div className="error-message">
            Please complete all the required fields!
          </div>
        )}
      </form>
    </div>
  );
}