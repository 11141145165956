"use client";
import "./App.css";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

export function ProtectionPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const navigate = useNavigate();

  const correctUsername = "rEvolutionName";
  const correctPassword = "rEvolutioN123";

  const getUsernamePassValidation = useCallback(() => {
    if (!username || !password) {
      return `login-error-message hide-message`;
    }
    if (username.length === 0 || password.length === 0) {
      return `login-error-message hide-message`;
    }
    if (username !== correctUsername || password !== correctPassword) {
      return `login-error-message`;
    }
    return `login-error-message hide-message`;
  }, [password, username]);

  const handleLoginSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (username === correctUsername && password === correctPassword) {
        setErrorMessage("");
        navigate("/checkout");
      } else {
        setErrorMessage("The username or password are invalid!");
      }
    },
    [navigate, password, username]
  );

  const getUsername = useCallback((e) => {
    setUsername(e.target.value);
  }, []);

  const getPassword = useCallback((e) => {
    setPassword(e.target.value);
  }, []);


  return (
    <>
      <div className="protection-container">
        <form onSubmit={handleLoginSubmit} id="protectionForm">
          <div className="protection-page-title">
            <FontAwesomeIcon icon={faUser} />
            <p className="protection-form-title"> Log in</p>
          </div>
          <div className="protection-page-section">
            <label>Username</label>
            <input
              type="text"
              name="username"
              placeholder="Please insert your username"
              value={username}
              onChange={getUsername}
              required
            />
          </div>
          <div className="protection-page-section">
            <label>Password</label>
            <input
              type="password"
              name="password"
              placeholder="Please insert your password"
              value={password}
              onChange={getPassword}
              required
            />
          </div>
          <input
            type="submit"
            value="Log in"
            className="btn btn-success protection-btn"
            disabled={!username || !password}
          />
          <span className={getUsernamePassValidation()}>{errorMessage}</span>
        </form>
      </div>
    </>
  );
}