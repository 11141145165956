export async function getAccessToken(url, clientOptions) {
  const tokenUrl = `${url}?grant_type=client_credentials&client_id=${clientOptions.clientId}&client_secret=${clientOptions.clientSecret}`;

  const res = await fetch(tokenUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });

  const data = await res.json();

  console.log("Access token", data);

  return data;
}

export async function createPaymentIntent(
  paymentUrl,
  accessToken,
  correlationId,
  amount,
  orderId,
  name,
  email,
  siteUrl
) {
  const res = await fetch(paymentUrl, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
      "x-api-key": "da2-wexuyemwpjf5xhjwv2z3ezqp4m",
    },
    body: JSON.stringify({
      query: `mutation Payments{
            PaymentIntentCreate(
                Request: {
                    Amount: ${amount * 100},
                    ConfirmIntent: false
                    Currency: usd,
                    CaptureMethod: automatic,
                    RequestContext: {
                        Brand: Lambo
                        Country: US
                        ProgramCode: "LAMBOUS"
                        CorrelationId: "${correlationId}",
                    },
                    Metadata: "{\\"name\\":\\"${name}\\", \\"email\\":\\"${email}\\", \\"orderID\\":\\"${orderId}\\", \\"siteUrl\\":\\"${siteUrl}\\"}"
                },
            ){
                ClientSecret
                PaymentIntentId
                OperationResult{
                    IsSuccess
                    Errors{
                        ErrorCode
                        ErrorMessage
                        ErrorStack
                    }
                }
            }
        }`,
    }),
  });

  const data = await res.json();

  console.log("PaymentIntentCreate", data);

  return data;
}

export async function confirmPaymentIntent(
  paymentUrl,
  accessToken,
  paymentIntentId,
  confirmationToken,
  correlationId
) {
  const res = await fetch(paymentUrl, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: `mutation Payments{
                PaymentIntentConfirm(
                Request: {
                    PaymentIntentId: "${paymentIntentId}",
                    ConfirmationToken: "${confirmationToken}"
                    RequestContext: {
                        Brand: Lambo
                        Country: US
                        ProgramCode: "TESTLAMBO"
                        CorrelationId: "${correlationId}",
                    },
                }
            ){
                RawData
                PaymentIntentId
                OperationResult{
                    IsSuccess
                    Errors{
                        ErrorCode
                        ErrorMessage
                        ErrorStack
                    }
                }
            }
        }`,
    }),
  });

  const data = await res.json();

  console.log("PaymentIntentConfirm", data);

  return data;
}