import { AddressElement } from "@stripe/react-stripe-js";

export function AddressForm() {
  const addressOptions = {
    mode: "billing",
    allowedCountries: ["US"],
    validationMode: "always",
  };

  return (
    <>
      <AddressElement options={addressOptions} />
    </>
  );
}
